import React, { useState } from "react";

const Card = ({ bookInfo, index, deleteBook, updateBookDetails }) => {
  const dateParts = bookInfo.publishedDate.split("-");
  const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const lessText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  const handleInputChange = (field, value) => {
    updateBookDetails(index, field, value);
  };

  const handleStarClick = (selectedRating) => {
    updateBookDetails(index, "rating", selectedRating);
  };
  return (
    <div className="book-card">
      {bookInfo.coverImage && (
        <img
          src={bookInfo.coverImage}
          alt={`Couverture du livre ${bookInfo.title}`}
        />
      )}
      <p>
        <span className="blue title">{bookInfo.title}</span>
      </p>
      <p>
        <span className="blue">Auteur(s):</span>
        <br />
        {bookInfo.authors ? bookInfo.authors.join(", ") : "N/A"}
      </p>
      <p>
        <span className="blue">Date de publication :</span>
        <br />
        {formattedDate}
      </p>
      <p>
        <span className="blue">Style littéraire :</span> <br />
        {bookInfo.categories}
      </p>
      <p>
        <span className="blue">Nombre de pages :</span> <br />
        {bookInfo.pageCount}
      </p>
      <p className="description">
        <span className="blue">Résumé :</span> <br />{" "}
        {isExpanded
          ? bookInfo.description
          : lessText(bookInfo.description, 150)}
        {bookInfo.description && (
          <button className="btn btn-expand" onClick={toggleExpand}>
            {isExpanded ? "Réduire" : "Lire plus"}
          </button>
        )}
      </p>
      <div className="avis">
        <p>
          <span className="blue">Date de lecture :</span> <br />
          <input
            name="date"
            id="date"
            type="date"
            placeholder="Date de lecture"
            value={bookInfo.readDate}
            onChange={(e) => handleInputChange("readDate", e.target.value)}
          />
        </p>
        <p>
          <span className="blue">Note :</span> <br />
          {[1, 2, 3, 4, 5].map((star, index) => (
            <span
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => handleStarClick(star)}
            >
              {star <= bookInfo.rating ? "🌟" : "☆"}
            </span>
          ))}
        </p>
      </div>
      <button
        className="btn btn-delete"
        onClick={() => deleteBook(bookInfo.title)}
      >
        Supprimer la fiche
      </button>
    </div>
  );
};

export default Card;
