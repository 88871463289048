import React from "react";

const Fiches = ({ bookInfo, index, deleteBook, updateBookDetails }) => {
  const dateParts = bookInfo.publishedDate.split("-");
  const formattedDate = `${dateParts[0]}`;

  const handleStarClick = (selectedRating) => {
    updateBookDetails(index, "rating", selectedRating);
  };
  const handleInputChange = (field, value) => {
    updateBookDetails(index, field, value);
  };

  return (
    <div className="book-card">
      {bookInfo.coverImage && (
        <img
          src={bookInfo.coverImage}
          alt={`Couverture du livre ${bookInfo.title}`}
        />
      )}
      <div className="bloc-book">
        <h1>
          <span className="title">{bookInfo.title}</span>
        </h1>
        <p>
          <span className="blue">
            {bookInfo.authors ? bookInfo.authors.join(", ") : "N/A"}
          </span>
        </p>
        <p>
          <span className="blue">{formattedDate}</span>
        </p>
        <p>
          <span className="blue">{bookInfo.categories}</span>
        </p>
        <p>
          <span className="blue">{bookInfo.pageCount} pages</span>
        </p>
        <div className="avis">
          <p className="blue">
            Fini le :{" "}
            <input
              className="blue"
              name="date"
              id="date"
              type="date"
              placeholder="Date de lecture"
              value={bookInfo.readDate}
              onChange={(e) => handleInputChange("readDate", e.target.value)}
            />
          </p>
          <p>
            {[1, 2, 3, 4, 5].map((star, index) => (
              <span
                key={star}
                className={star <= bookInfo.rating ? "star full" : "star empty"}
                style={{ cursor: "pointer" }}
                onClick={() => handleStarClick(star)}
              >
                &#9733;
              </span>
            ))}
          </p>
        </div>
        <button
          className="btn btn-delete"
          onClick={() => deleteBook(bookInfo.title)}
        >
          Supprimer la fiche
        </button>
      </div>
    </div>
  );
};

export default Fiches;
