import React, { useState, useEffect } from "react";
import logo from "../assets/img/ardm-logo.png";
import Fiches from "../components/Fiches";
// import Categories from "../components/Categories";

const Home = () => {
  const [bookTitle, setBookTitle] = useState("");
  const [bookResults, setBookResults] = useState([]);
  const [savedBooks, setSavedBooks] = useState([]);
  const [showBookResults, setShowBookResults] = useState(false);

  useEffect(() => {
    const storedBooks = JSON.parse(localStorage.getItem("savedBooks")) || [];
    setSavedBooks(storedBooks);
  }, []);

  const fetchBookInfo = async (title) => {
    const apiKey = "AIzaSyDTejzLdrOqRAD_irI-YZ1LDsJcnmjQ3VA";
    const url = `https://www.googleapis.com/books/v1/volumes?q=intitle:${encodeURIComponent(title)}&key=${apiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.items && data.items.length > 0) {
          const results = data.items.map((item) => {
            const book = item.volumeInfo;
            return {
              id: item.id,
              coverImage: book.imageLinks ? book.imageLinks.thumbnail : null,
              title: book.title,
              authors: book.authors,
              publishedDate: book.publishedDate,
              categories: book.categories,
              pageCount: book.pageCount,
              description: book.description,
              rating: "",
              readDate: "",
            };
          });
          setBookResults(results);
          setShowBookResults(true);
        } else {
          setBookResults([]);
          setShowBookResults(false);
          alert(`Aucun résultat trouvé pour "${title}".`);
        }
      });
  };

  const saveBook = (bookInfo) => {
    if (bookInfo) {
      if (!savedBooks.some((savedBook) => savedBook.title === bookInfo.title)) {
        const updatedBooks = [...savedBooks, bookInfo];
        localStorage.setItem("savedBooks", JSON.stringify(updatedBooks));
        setSavedBooks(updatedBooks);
        setShowBookResults(false);
        alert(`Le livre "${bookInfo.title}" a été sauvegardé.`);
      } else {
        alert(`Le livre "${bookInfo.title}" est déjà sauvegardé.`);
      }
    }
  };

  const deleteBook = (title) => {
    const updatedBooks = savedBooks.filter(
      (savedBook) => savedBook.title !== title
    );
    localStorage.setItem("savedBooks", JSON.stringify(updatedBooks));
    setSavedBooks(updatedBooks);
    alert(`Le livre "${title}" a été supprimé.`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchBookInfo(bookTitle);
    setShowBookResults(true);
  };

  const updateBookDetails = (index, field, value) => {
    const updatedBooks = [...savedBooks];
    updatedBooks[index][field] = value;
    localStorage.setItem("savedBooks", JSON.stringify(updatedBooks));
    setSavedBooks(updatedBooks);
  };

  const sortBooksByReadDate = () => {
    const sortedBooks = [...savedBooks].sort((a, b) => {
      return new Date(b.readDate) - new Date(a.readDate);
    });
    setSavedBooks(sortedBooks);
  };

  return (
    <div>
      <div className="header">
        <img src={logo} alt="Logo au rayon des Merveilles" className="logo" />
      </div>

      <form className="form-search" onSubmit={handleSubmit}>
        <input
          name="bookTitle"
          id="bookTitle"
          type="text"
          value={bookTitle}
          onChange={(e) => setBookTitle(e.target.value)}
          placeholder="Nom du livre"
        />
        <button type="submit">Chercher</button>
      </form>

      {showBookResults && bookResults.length > 0 && (
        <div className="container-saved-book">
          <h2>Résultats pour : "{bookTitle}"</h2>
          <div className="saved-books">
            {bookResults.map((book) => (
              <div key={book.id} className="book-card">
                {book.coverImage && (
                  <img src={book.coverImage} alt={`couverture ${book.title}`} />
                )}
                <div className="bloc-book">
                  <h1>
                    <span className="blue title">{book.title}</span>
                  </h1>
                  <p>
                    <span className="blue results">
                      {book.authors ? book.authors.join(", ") : "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="blue results">{book.publishedDate}</span>
                  </p>
                  <p>
                    <span className="blue results">
                      {book.categories ? book.categories.join(", ") : "N/A"}
                    </span>
                  </p>
                  <p>
                    <span className="blue results">{book.pageCount} pages</span>
                  </p>
                  <button
                    className="btn btn-fiche"
                    onClick={() => saveBook(book)}
                  >
                    Créer une fiche
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* <Categories /> */}

      <div>
        {savedBooks.length > 0 && (
          <div className="container-saved-book">
            <button className="btn-trie" onClick={sortBooksByReadDate}>
              Trier par date de lecture
            </button>
            <div className="saved-books">
              {savedBooks.map((card, index) => (
                <Fiches
                  key={index}
                  bookInfo={card}
                  index={index}
                  deleteBook={deleteBook}
                  updateBookDetails={updateBookDetails}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <p className="footer">
        Design graphique fait avec ♥ par Studio céhel | ©2024 au rayon des
        Merveilles
      </p>
    </div>
  );
};

export default Home;
